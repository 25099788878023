<template>
  <div class="modal fade" tabindex="-10" id="modal_add_corso">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ values }"
        :initial-values="formValues"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inserimento corso</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <!-- <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Stagione*</label>
                      <SelectInput
                        :options="stagioni"
                        name="stagione"
                        placeholder="Seleziona"
                        :value="stagione"
                        @changeSelect="setCorsoStagione($event)"
                      />
                    </div> -->
                    <div class="col-md-12">
                      <label class="fw-bold text-gray-600"
                        >Denominazione*</label
                      >
                      <!--<TextInput
                      name="denominazione"
                      v-model="denominazione"
                      :value="denominazione"
                      type="text"
                    />-->
                      <Field
                        name="denominazione"
                        type="text"
                        v-model="denominazione"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="denominazione"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Tipologia corso*</label
                      >
                      <!--<SelectInput
                      :options="snm_corsi_tipo"
                      name="id_Tipo_Corso"
                      placeholder="Seleziona"
                      :value="id_Tipo_Corso"
                      @changeSelect="
                        setCorsoTipo($event);
                        getSpecializzCorsi(id_Tipo_Corso);
                        setCorsoSottotipo(null);
                        resetCorsiSpec();
                      "
                    />-->
                      <Field
                        name="id_Tipo_Corso"
                        as="select"
                        class="form-select"
                        v-model="id_Tipo_Corso"
                        @change="
                          setCorsoTipo($event.target.value);
                          getSpecializzCorsi($event.target.value);
                          setCorsoSottotipo(null);
                          resetCorsiSpec();
                          setCorsoCostoBase($event);
                        "
                      >
                        <option value="0">Seleziona</option>
                        <option
                          v-for="tipo in snm_corsi_tipo"
                          :key="tipo.id"
                          :value="tipo.id"
                          :data-costo="tipo.costo_base"
                        >
                          {{ tipo.label }}
                        </option>
                      </Field>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Tipo_Corso"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Specializzazione corso*</label
                      >
                      <SelectInput
                        v-if="id_Tipo_Corso != 9"
                        :options="snm_corsi_sottotipo"
                        name="id_Sottotipo_Corso"
                        id="id_Sottotipo_Corso"
                        placeholder="Seleziona"
                        :value="id_Sottotipo_Corso"
                        :disabled="id_Tipo_Corso ? false : true"
                        @changeSelect="setCorsoSottotipo($event)"
                      />
                      <Multiselect
                        v-if="id_Tipo_Corso == 9"
                        v-model="selectedSottotipo"
                        :options="snm_corsi_sottotipo"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        @input="inputElement"
                      />
                      <Field
                        v-if="id_Tipo_Corso == 9"
                        name="id_sottotipo_corso_agg"
                        type="hidden"
                        v-model="id_sottotipo_corso_agg"
                        class="form-control"
                      />
                      <ErrorMessage
                        v-if="id_Tipo_Corso == 9"
                        class="help-message"
                        style="color: red"
                        name="id_sottotipo_corso_agg"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600">Costo €*</label>
                      <!--<TextInput
                      name="costo"
                      v-model="costo"
                      type="text"
                      @InputEvent="validateNumber($event)"
                    />-->
                      <Field
                        name="costo"
                        type="text"
                        v-model="costo"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="costo"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Inizio corso*</label>
                      <!-- :minDate="new Date()" -->
                      <Datepicker
                        v-model="data_Inizio"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="data_Inizio"
                        type="hidden"
                        class="form-control"
                        v-model="data_Inizio"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Inizio"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Fine corso*</label>
                      <!-- :minDate="new Date(data_Inizio)" -->
                      <Datepicker
                        v-model="data_Fine"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="data_Fine"
                        type="hidden"
                        class="form-control"
                        v-model="data_Fine"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Fine"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Inizio prenotazioni*</label
                      >
                      <Datepicker
                        v-model="iscr_Inizio"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="iscr_Inizio"
                        type="hidden"
                        class="form-control"
                        v-model="iscr_Inizio"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="iscr_Inizio"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Fine prenotazioni*</label
                      >
                      <Datepicker
                        v-model="iscr_Fine"
                        placeholder=""
                        :minDate="new Date(iscr_Inizio)"
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="iscr_Fine"
                        type="hidden"
                        class="form-control"
                        v-model="iscr_Fine"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="iscr_Fine"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data pubblicazione</label
                      >
                      <Datepicker
                        v-model="pubblicazione_Sul_Sito"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="pubblicazione_Sul_Sito"
                        type="hidden"
                        class="form-control"
                        v-model="pubblicazione_Sul_Sito"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="pubblicazione_Sul_Sito"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Visibile online*</label
                      >
                      <SelectInput
                        :options="lookupSTDSiNo"
                        name="visibile_online"
                        placeholder="-"
                        :value="null"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Iscrizione online*</label
                      >
                      <SelectInput
                        :options="lookupSTDSiNo"
                        name="iscrizione_online"
                        placeholder="-"
                        :value="null"
                        @changeSelect="values.id_livello_visibilita = null"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Livello di visibilità*</label
                      >
                      <SelectInput
                        :options="snm_corsi_livelli_visibilita"
                        :disabled="values.iscrizione_online == 1 ? false : true"
                        name="id_livello_visibilita"
                        placeholder="-"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Crediti n.*</label>
                      <!--<TextInput
                      name="crediti"
                      v-model="crediti"
                      type="text"
                      @InputEvent="validateNumber($event)"
                    />-->
                      <Field
                        name="crediti"
                        type="text"
                        v-model="crediti"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="crediti"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Curatore</label>
                      <!--<TextInput name="curatore" v-model="curatore" type="text" />-->
                      <Field
                        name="curatore"
                        type="text"
                        v-model="curatore"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <!-- <div class="row">
                  <div class="col-lg-3">
                    <label class="fw-bold text-gray-600">
                      Informazioni corso:
                    </label>
                  </div>
                  <div class="col-lg-9 d-flex ps-0 pe-xl-11">
                    <input
                      type="text"
                      class="form-control me-2"
                      placeholder=""
                      aria-label=""
                    />
                    <div
                      class="image-input image-input-outline"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    >
                      <label
                        class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change file"
                      >
                        <i class="bi bi-upload fs-7"></i>

                        <input
                          type="file"
                          name="avatar"
                          accept=".pdf, .doc, .jpeg, .ppt, .xls, .docx, .pptx, .xlsx, .jpg, .jpeg, .png"
                        />
                        <input type="hidden" name="avatar_remove" />
                      </label>
                    </div>
                  </div>
                </div> -->
                  <div class="row">
                    <div class="col-md-12">
                      <label class="fw-bold text-gray-600"
                        >Informazioni Corso</label
                      >
                      <DropZone
                        @files-dropped="addFile($event, supportedFormatCorsi)"
                        #default="{ dropZoneActive }"
                      >
                        <label for="file-input-add" style="cursor: pointer">
                          <span v-if="dropZoneActive">
                            <span>Lascia qui il file </span>
                            <span class="smaller">per aggiungerlo</span>
                          </span>
                          <span v-else>
                            <span>Trascina qui il tuo file</span>
                            <span class="smaller">
                              oppure
                              <strong><em>clicca qui</em></strong>
                              per selezionare il file
                            </span>
                          </span>
                        </label>
                        <input
                          type="file"
                          id="file-input-add"
                          style="display: none"
                          :accept="supportedFormatCorsi"
                          @change="onInputChange"
                        />
                        <ul class="image-list" v-if="file.id">
                          <FilePreview
                            :key="file.id"
                            :file="file"
                            tag="li"
                            @remove="removeFile"
                          />
                        </ul>
                      </DropZone>
                      <div class="form-text">
                        Tipi di file concessi: {{ supportedFormatCorsi }}
                      </div>
                    </div>
                  </div>

                  <hr class="mb-4 mt-4" />
                  <div class="row">
                    <div class="col-md-2 mb-4">
                      <Field
                        v-model="estero"
                        class="form-check-input mt-8"
                        type="radio"
                        name="estero"
                        id="italia"
                        :value="0"
                        :checked="!estero"
                        @click="resetLuogo()"
                      />
                      <label class="fw-bold text-gray-600" for="italia"
                        >Italia</label
                      >
                    </div>
                    <div class="col-md-2 mb-4">
                      <Field
                        v-model="estero"
                        class="form-check-input mt-8"
                        type="radio"
                        name="estero"
                        id="estero"
                        :value="1"
                        :checked="estero"
                        @click="resetLuogo()"
                      />
                      <label class="fw-bold text-gray-600" for="estero"
                        >Estero</label
                      >
                    </div>
                  </div>
                  <div class="row pb-4" v-if="estero">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Nazione*</label>
                      <SelectInput
                        :options="statiResidenza"
                        :value="stato"
                        name="stato"
                        placeholder="Seleziona"
                        @changeSelect="setNazioneCorso($event)"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Località*</label>
                      <!--<TextInput name="localita" v-model="localita" type="text" />-->
                      <Field
                        name="localita"
                        type="text"
                        v-model="localita"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="localita"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-else>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Regione*</label>
                      <Field
                        name="id_Regione"
                        as="select"
                        class="form-select"
                        v-model="id_Regione"
                        @change="
                          setRegioneCorso($event);
                          filterProvince($event);
                          id_Provincia = 0;
                          comune_txt = '';
                          id_Comune = 0;
                        "
                      >
                        <option value="0">Seleziona</option>
                        <!--<option
                        v-for="regione in istat_regioni_province"
                        :key="regione.codR"
                        :value="regione.Province"
                        :id="regione.codR"
                      >-->
                        <option
                          v-for="regione in istat_regioni_province"
                          :key="regione.codR"
                          :value="regione.codR"
                          :id="regione.codR"
                        >
                          {{ regione.nomeR }}
                        </option>
                      </Field>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Regione"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Provincia*</label>
                      <Field
                        name="id_Provincia"
                        as="select"
                        class="form-select"
                        v-model="id_Provincia"
                        @change="
                          setProvinciaCorso($event.target.value);
                          comune_txt = '';
                          id_Comune = 0;
                        "
                      >
                        <option value="0">Seleziona</option>
                        <option
                          v-for="provincia in provincetest"
                          :key="provincia.codP"
                          :value="provincia.codP"
                        >
                          {{ provincia.nomeP }}
                        </option>
                      </Field>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Provincia"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Comune*</label>
                      <Field
                        name="comune"
                        type="text"
                        class="form-control"
                        @input="
                          spliceComuniIstat();
                          setComuni(comune_txt, id_Provincia);
                        "
                        @focus="inputFocus = 'comuneRD'"
                        @keyup.delete="spliceComuniIstat()"
                        v-model="comune_txt"
                        :disabled="selectedProvincia ? false : true"
                        autocomplete="off"
                      />
                      <Field
                        name="id_Comune"
                        type="hidden"
                        v-model="id_Comune"
                        class="form-control"
                      />
                      <ListComuni
                        v-if="comuniIstat && inputFocus === 'comuneRD'"
                        :list="comuniIstat"
                        @hideList="spliceComuniIstat"
                        @selectedElement="
                          spliceComuniIstat();
                          id_Comune = $event.value;
                          comune_txt = $event.name;
                        "
                      ></ListComuni>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Comune"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Luogo</label>
                      <!--<TextInput name="luogo" v-model="luogo" type="text" />-->
                      <Field
                        name="luogo"
                        type="text"
                        v-model="luogo"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="luogo"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Telefono</label>
                      <Field
                        name="telefono"
                        type="text"
                        v-model="telefono"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="telefono"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Fax</label>
                      <Field
                        name="fax"
                        type="text"
                        v-model="fax"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="fax"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">C.A.P.</label>
                      <!--<TextInput name="cap" v-model="cap" type="text" />-->
                      <Field
                        name="cap"
                        type="text"
                        v-model="cap"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="cap"
                      />
                    </div>
                  </div>
                  <hr class="mb-4 mt-4" />
                  <div class="row pb-4">
                    <p>Organizzatore:</p>
                    <div class="col-md-5">
                      <label class="fw-bold text-gray-600">Tipologia*</label>
                      <SelectInput
                        :options="snm_tipo_organizzazione"
                        name="org_Tipo"
                        :value="org_Tipo"
                        @changeSelect="setCorsoTipoOrganizzazione($event)"
                      />
                    </div>
                    <div class="col-md-7">
                      <label class="fw-bold text-gray-600"
                        >Denominazione*</label
                      >
                      <div v-if="org_Tipo === 1"></div>
                      <div v-else-if="org_Tipo === 2">
                        <Field
                          name="selectedComitati"
                          as="select"
                          class="form-select"
                          v-model="selectedComitati"
                          @change="
                            org_Denominazione = $event.target.value;
                            selectedComitati = $event.target.value;
                          "
                        >
                          <option :value="null">Seleziona</option>
                          <option
                            v-for="comitato in comitati"
                            :key="comitato.label"
                            :value="comitato.label"
                          >
                            {{ comitato.label }}
                          </option>
                        </Field>
                      </div>
                      <div v-else-if="org_Tipo === 3">
                        <input
                          name="nome_societa"
                          type="text"
                          class="form-control"
                          @input="
                            getSocieta($event.target.value);
                            org_Denominazione = '';
                            nome_societa === ''
                              ? (org_Denominazione = '')
                              : (org_Denominazione = '');
                          "
                          v-model="nome_societa"
                          autocomplete="off"
                        />
                        <!--<Field
                        name="org_Denominazione"
                        type="hidden"
                        v-model="org_Denominazione"
                        :value="org_Denominazione"
                        class="form-control"
                      />-->
                        <ListSocieta
                          :focusOnInput="true"
                          :list="societa"
                          @hideList="resetSocieta"
                          @selectedSocieta="
                            resetSocieta();
                            nome_societa = $event.name;
                            org_Denominazione = $event.name;
                          "
                        ></ListSocieta>
                        <!--<ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="org_Denominazione"
                      />-->
                      </div>
                      <div v-else-if="org_Tipo === 6">
                        <!--<TextInput
                        name="org_Denominazione"
                        v-model="org_Denominazione"
                        :value="org_Denominazione"
                        type="text"
                      />-->
                        <!--<Field
                        name="org_Denominazione"
                        type="text"
                        v-model="org_Denominazione"
                        class="form-control"
                      />
                      <ErrorMessage name="org_Denominazione" />-->
                      </div>
                      <div v-else>
                        <input type="text" disabled="" class="form-control" />
                      </div>
                      <Field
                        name="org_Denominazione"
                        type="hidden"
                        v-model="org_Denominazione"
                        class="form-control"
                        id="org_Denominazione"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="org_Denominazione"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              Chiudi
            </button>
            <button
              type="button"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Aggiungi
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
//import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import ListComuni from "@/components/components-fit/utility/ListComuni.vue";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import "vue3-date-time-picker/dist/main.css";
import { addCorso } from "@/requests/snmCorsi";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import DropZone from "../../Drag-and-Drop-File/DropZone.vue";
import FilePreview from "../../Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";
import { sendFiles } from "@/requests/fileSender";
import { alertFailed } from "@/composables/swAlert";

import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "AddCorso",
  components: {
    Loading,
    Datepicker,
    Form,
    SelectInput,
    //TextInput,
    ErrorMessage,
    Field,
    ListComuni,
    ListSocieta,
    DropZone,
    FilePreview,
    Multiselect,
  },
  emits: ["refreshList"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const snm_corsi_tipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_tipo")
    );
    const snm_corsi_sottotipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_sottotipo")
    );
    const istat_regioni_province = computed(() =>
      store.getters.getStateFromName("istat_regioni_province")
    );
    const statiResidenza = computed(() =>
      store.getters.getStateFromName("stati_residenza")
    );
    const snm_tipo_organizzazione = computed(() =>
      store.getters.getStateFromName("snm_tipo_organizzazione")
    );
    //const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const snm_corsi_livelli_visibilita = computed(() =>
      store.getters.getStateFromName("snm_corsi_livelli_visibilita")
    );

    const keys = ref("sct|irp|sr|scto|c|sclv");
    if (
      !snm_corsi_tipo.value ||
      !istat_regioni_province.value ||
      !statiResidenza.value ||
      !snm_tipo_organizzazione.value ||
      !snm_corsi_livelli_visibilita.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getSpecializzCorsi = (id) => {
      if (!isNaN(id) && String(id) != "") {
        store.dispatch("setStoreData", {
          keys: { id_Tipo_Corso: id },
          apiLink: globalApi.LOOKUP_SPECIALIZZAZIONE_CORSI,
        });
      }
    };

    //const provincetest = ref({});

    const provincetest = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    store.commit("spliceArrayFromName", "istat_province");

    const filterProvince = (e) => {
      //  e.target.selectedOptions[0]._value era presente array delle province
      // in option di ogni regione presente array delle province
      /*store.commit("assignStateValue", {
        value: e.target.selectedOptions[0]._value,
        stateName: "province",
      });*/
      if (
        !isNaN(e.target.selectedOptions[0].id) &&
        e.target.selectedOptions[0].id != null &&
        String(e.target.selectedOptions[0].id) != ""
      ) {
        store.dispatch("setStoreData", {
          keys: { codR: parseInt(e.target.selectedOptions[0].id) },
          apiLink: globalApi.LUOGHI_PROVINCE_ISTAT,
        });
      } else {
        store.commit("spliceArrayFromName", "istat_province");
        provincetest.value = {};
      }
    };

    const alertCampiObb = ref(false);
    const defaultStagione = computed(() => store.getters.defaultStagione);
    const stagione = ref(0);
    // stagione.value = defaultStagione.value;
    const denominazione = ref("");
    const id_Tipo_Corso = ref(0);
    const id_Sottotipo_Corso = ref(null);
    const id_sottotipo_corso_agg = ref("");
    const costo = ref(null);
    const data_Inizio = ref(null);
    const data_Fine = ref(null);
    const iscr_Inizio = ref(null);
    const iscr_Fine = ref(null);
    const pubblicazione_Sul_Sito = ref(null);
    const crediti = ref(null);
    const curatore = ref("");
    const estero = ref(0);
    const id_Regione = ref(0);
    const id_Provincia = ref(0);
    const comune_txt = ref("");
    const inputFocus = ref("");
    const id_Comune = ref(0);
    const selectedProvincia = ref(false);
    const stato = ref("");
    const localita = ref("");
    const luogo = ref("");
    const telefono = ref("");
    const fax = ref("");
    const cap = ref("");
    const org_Tipo = ref(null);
    const org_Denominazione = ref("");
    const nome_societa = ref("");
    const selectedComitati = ref(null);

    const setCorsoStagione = (id) => {
      stagione.value = parseInt(id);
    };

    const setCorsoTipo = (id) => {
      id_Tipo_Corso.value = parseInt(id);
    };

    const setCorsoCostoBase = (e) => {
      costo.value = e.target.selectedOptions[0].getAttribute("data-costo");
    };

    const setCorsoSottotipo = (id) => {
      id_Sottotipo_Corso.value = parseInt(id);
    };

    const resetCorsiSpec = () => {
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      //specializzCorsi.value = null;
    };

    const setRegioneCorso = (e) => {
      id_Regione.value = parseInt(e.target.selectedOptions[0].id);
    };

    const setProvinciaCorso = (id) => {
      if (isNaN(id)) {
        id_Provincia.value = null;
        selectedProvincia.value = false;
        id_Comune.value = 0;
      } else if (id != id_Provincia.value) {
        selectedProvincia.value = true;
        id_Provincia.value = parseInt(id);
        id_Comune.value = 0;
      } else {
        selectedProvincia.value = true;
      }
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    const setComuni = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const setNazioneCorso = (id) => {
      stato.value = parseInt(id);
    };

    const resetLuogo = () => {
      id_Regione.value = 0;
      id_Provincia.value = 0;
      comune_txt.value = "";
      inputFocus.value = "";
      id_Comune.value = 0;
      selectedProvincia.value = false;
      stato.value = "";
      localita.value = "";
      luogo.value = "";
      cap.value = "";
    };

    const setCorsoTipoOrganizzazione = (id) => {
      org_Denominazione.value = "";
      nome_societa.value = "";
      org_Tipo.value = parseInt(id);
      switch (org_Tipo.value) {
        case 1:
          document.getElementById("org_Denominazione").type = "text";
          org_Denominazione.value = "Federazione Italiana Tennis e Padel";
          break;
        case 2:
        case 3:
          document.getElementById("org_Denominazione").type = "hidden";
          break;
        case 6:
          document.getElementById("org_Denominazione").type = "text";
          break;
        default:
          document.getElementById("org_Denominazione").type = "hidden";
      }
    };

    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    const spliceSocieta = () => {
      nome_societa.value = "";
      org_Denominazione.value = "";
      resetSocieta();
    };

    const setCorsoOrganizzatoreComitato = (id) => {
      selectedComitati.value = parseInt(id);
    };

    const schema = yup.object().shape({
      stagione: yup.number(),
      denominazione: yup.string().required("Obbligatorio"),
      id_Tipo_Corso: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .moreThan(0, "Obbligatorio")
        .nullable(),
      id_Sottotipo_Corso: yup
        .number()
        .when("id_Tipo_Corso", {
          is: (id_Tipo_Corso) => id_Tipo_Corso != 9,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .test("notEmpty", "Obbligatorio", function (value) {
              if (document.getElementById("id_Sottotipo_Corso").length < 2) {
                return true;
              } else {
                if (value != null) {
                  return true;
                } else {
                  return false;
                }
              }
            })
            .nullable(),
        })
        .nullable(),
      id_sottotipo_corso_agg: yup.string().when("id_Tipo_Corso", {
        is: (id_Tipo_Corso) => id_Tipo_Corso == 9,
        then: yup
          .string()
          .required("Obbligatorio")
          .nullable()
          .typeError("Obbligatorio"),
      }),
      costo: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      data_Inizio: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"),
      data_Fine: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"),
      visibile_online: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      iscrizione_online: yup
        .number()
        .nullable()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_livello_visibilita: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("iscrizione_online", {
          is: (value) => {
            return value == 1;
          },
          then: yup
            .number()
            .required("Obbligatorio")
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup.number().nullable(),
        }),
      crediti: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio"),
      curatore: yup.string(),
      stato: yup.string().when("estero", {
        is: (estero) => estero === 1,
        then: yup.string().required("Obbligatorio"),
      }),
      localita: yup.string().when("estero", {
        is: (estero) => estero === 1,
        then: yup.string().required("Obbligatorio"),
      }),
      id_Regione: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("estero", {
          is: (estero) => estero === 0,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .moreThan(0, "Obbligatorio")
            .nullable(),
        }),
      // .required("Obbligatorio"),
      id_Provincia: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("estero", {
          is: (estero) => estero === 0,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .moreThan(0, "Obbligatorio")
            .nullable(),
        }),
      id_Comune: yup.number().when("estero", {
        is: (estero) => estero === 0,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Obbligatorio")
          .nullable()
          .moreThan(0, "Obbligatorio"),
      }),
      org_Tipo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      /* org_Denominazione: yup.string().when("org_Tipo", {
        is: (org_Tipo) => org_Tipo > 0,
        then: yup.string().required("Obbligatorio"),
      }), */
      org_Denominazione: yup.string().required("Obbligatorio").nullable(),
      iscr_Inizio: yup.string().required("Obbligatorio").nullable(),
      iscr_Fine: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      isLoading.value = true;
      alertCampiObb.value = false;
      // const responseAdd = ref({});
      await addCorso(values).then(async (value) => {
        if (value.status == 200) {
          // value.data.id
          if (file.value.file) {
            let bodyFormData = new FormData();
            bodyFormData.append("id_Corso", value.data.id);
            bodyFormData.append("anno", values.stagione);
            bodyFormData.append("id_Tipo", values.id_Tipo_Corso);
            bodyFormData.append("acl", 0);
            if (file.value.file) {
              bodyFormData.append("file", file.value.file);
            }
            // file.value.status = "loading";
            await sendFiles(
              bodyFormData,
              globalApi.AGGIUNGI_DOCUMENTO_CORSI
            ).then((res) => {
              // const { status, data } = res;
              if (res.data.message) {
                file.value.status = false;
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore nell'invio del file. Riprovare più tardi"
                );
                return;
              } else {
                file.value.status = true;
              }
            });
            disableButton.value = false;
            isLoading.value = false;
          }

          Swal.fire({
            html: "Corso creato",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          file.value = {};
          resetForm();
          resetField();
          document.getElementById("closeModal").click();
          emit("refreshList");
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      // stagione.value = defaultStagione.value;
      denominazione.value = "";
      id_Tipo_Corso.value = 0;
      id_Sottotipo_Corso.value = null;
      id_sottotipo_corso_agg.value = "";
      costo.value = null;
      data_Inizio.value = null;
      data_Fine.value = null;
      iscr_Inizio.value = null;
      iscr_Fine.value = null;
      pubblicazione_Sul_Sito.value = null;
      crediti.value = null;
      curatore.value = "";
      estero.value = 0;
      id_Regione.value = 0;
      id_Provincia.value = 0;
      comune_txt.value = "";
      inputFocus.value = "";
      id_Comune.value = 0;
      selectedProvincia.value = false;
      stato.value = "";
      localita.value = "";
      luogo.value = "";
      telefono.value = "";
      fax.value = "";
      cap.value = "";
      org_Tipo.value = 0;
      org_Denominazione.value = "";
      nome_societa.value = "";
      selectedSottotipo.value = [];
      file.value = {};
    };

    const validateNumber = () => {
      //validazione numero
    };

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatCorsi.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const supportedFormatCorsi = ref(
      ".pdf, .zip, .doc, .ppt, .xls, .docx, .pptx, .xlsx, .jpg, .jpeg, .png"
    );

    const selectedSottotipo = ref([]);

    const inputElement = (selected) => {
      id_sottotipo_corso_agg.value = selected.join(",");
    };

    return {
      onInputChange,
      removeFile,
      addFile,
      file,
      estero,
      alertCampiObb,
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      format,
      stagioni: computed(() => store.getters.stagioni),
      // defaultStagione,
      stagione,
      denominazione,
      setCorsoStagione,
      snm_corsi_tipo,
      snm_corsi_sottotipo,
      id_Tipo_Corso,
      setCorsoTipo,
      getSpecializzCorsi,
      setCorsoSottotipo,
      resetCorsiSpec,
      id_Sottotipo_Corso,
      id_sottotipo_corso_agg,
      costo,
      data_Inizio,
      data_Fine,
      iscr_Inizio,
      iscr_Fine,
      pubblicazione_Sul_Sito,
      validateNumber,
      crediti,
      curatore,
      istat_regioni_province: computed(() =>
        store.getters.getStateFromName("istat_regioni_province")
      ),
      province: computed(() => store.getters.getStateFromName("province")),
      id_Regione,
      filterProvince,
      id_Provincia,
      setRegioneCorso,
      setProvinciaCorso,
      comune_txt,
      inputFocus,
      spliceComuniIstat,
      id_Comune,
      selectedProvincia,
      setComuni,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      statiResidenza,
      stato,
      setNazioneCorso,
      resetLuogo,
      localita,
      provincetest,
      luogo,
      telefono,
      fax,
      cap,
      snm_tipo_organizzazione,
      org_Tipo,
      setCorsoTipoOrganizzazione,
      org_Denominazione,
      comitati: computed(() => store.getters.getStateFromName("comitati")),
      nome_societa,
      getSocieta,
      resetSocieta,
      spliceSocieta,
      societa: computed(() => store.getters.societaNomeCodAff),
      selectedComitati,
      setCorsoOrganizzatoreComitato,
      setCorsoCostoBase,
      disableButton,
      isLoading,
      supportedFormatCorsi,
      selectedSottotipo,
      inputElement,
      formValues: {
        stagione: 0,
      },
      lookupSTDSiNo: [
        { id: 0, label: "No" },
        { id: 1, label: "Si" },
      ],
      snm_corsi_livelli_visibilita,
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
