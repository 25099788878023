<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_esporta_corsi"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Esportazione corsi</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="reset()"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row">
                <div class="col-sm-12">
                  <label class="fw-bold">Note esportazione:</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label=""
                    v-model="note"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="modalEsporta"
            @click="reset()"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="esporta()"
          >
            Richiedi esportazione
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import {
  esportaCorsi,
  esportaCorsiAggiornamentoDirigenti,
  esportaCorsiAggiornamentoNonDirigenti,
  esportaCorsiFormazioneDirigenti,
  esportaCorsiConPartecipanti,
} from "@/requests/esportazioniRequests";

import { useRouter } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertFailed } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "modal-esporta-corsi",
  components: { Loading },
  props: {
    esportazione_selezionata: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const stagione = computed(() => store.getters.stagioneSelected);
    const id_tipo_corso = computed(() => store.getters.tipologiaCorsi);
    const id_sottotipo_corso = computed(() => store.getters.specializzCorsi);
    const search = computed(() => store.getters.denominazioneCorsi);

    const note = ref(null);

    const isLoading = ref(false);

    const esporta = () => {
      isLoading.value = true;
      switch (props.esportazione_selezionata) {
        case "aggiornamento_dirigenti":
          esportaCorsiAggiornamentoDirigenti(
            stagione.value,
            search.value,
            note.value
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              Swal.fire({
                html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Lista Esportazioni",
                cancelButtonText: "Chiudi",
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-danger",
                },
              }).then((input) => {
                document.getElementById("modalEsporta").click();
                if (input.isConfirmed) {
                  // window.location.href = `/esportazioni`;
                  router.push(`/esportazioni`);
                  return;
                }
              });
              note.value = null;
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
          break;
        case "aggiornamento_non_dirigenti":
          esportaCorsiAggiornamentoNonDirigenti(
            stagione.value,
            search.value,
            note.value
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              Swal.fire({
                html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Lista Esportazioni",
                cancelButtonText: "Chiudi",
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-danger",
                },
              }).then((input) => {
                document.getElementById("modalEsporta").click();
                if (input.isConfirmed) {
                  // window.location.href = `/esportazioni`;
                  router.push(`/esportazioni`);
                  return;
                }
              });
              note.value = null;
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
          break;
        case "formazione_dirigenti":
          esportaCorsiFormazioneDirigenti(
            stagione.value,
            search.value,
            note.value
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              Swal.fire({
                html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Lista Esportazioni",
                cancelButtonText: "Chiudi",
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-danger",
                },
              }).then((input) => {
                document.getElementById("modalEsporta").click();
                if (input.isConfirmed) {
                  // window.location.href = `/esportazioni`;
                  router.push(`/esportazioni`);
                  return;
                }
              });
              note.value = null;
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
          break;
        case "con_partecipanti":
          esportaCorsiConPartecipanti(
            stagione.value,
            id_tipo_corso.value,
            id_sottotipo_corso.value,
            search.value,
            note.value
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              Swal.fire({
                html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Lista Esportazioni",
                cancelButtonText: "Chiudi",
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-danger",
                },
              }).then((input) => {
                document.getElementById("modalEsporta").click();
                if (input.isConfirmed) {
                  // window.location.href = `/esportazioni`;
                  router.push(`/esportazioni`);
                  return;
                }
              });
              note.value = null;
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
          break;
        case "corsi":
          esportaCorsi(
            stagione.value,
            id_tipo_corso.value,
            id_sottotipo_corso.value,
            search.value,
            note.value
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              Swal.fire({
                html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Lista Esportazioni",
                cancelButtonText: "Chiudi",
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-danger",
                },
              }).then((input) => {
                document.getElementById("modalEsporta").click();
                if (input.isConfirmed) {
                  // window.location.href = `/esportazioni`;
                  router.push(`/esportazioni`);
                  return;
                }
              });
              note.value = null;
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
          break;

        default:
          break;
      }
    };

    const reset = () => {
      note.value = null;
    };

    return {
      note,
      esporta,
      isLoading,
      reset,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
