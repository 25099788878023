<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-4" v-if="!readOnly">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#modal_add_corso"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi corso
          </button>
        </div>
      </div>

      <ModalEsportaCorsi :esportazione_selezionata="esportazione_selezionata" />
      <div
        class="col-4"
        @click="esportazione_selezionata = 'corsi'"
        :class="!readOnly ? 'text-center' : ''"
      >
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_corsi"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          corsi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-3"
        @click="esportazione_selezionata = 'aggiornamento_dirigenti'"
        :class="!readOnly ? 'text-center' : ''"
      >
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_corsi"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta corsi
          aggiornamento dirigenti
        </button>
      </div>
      <div
        class="col-3"
        @click="esportazione_selezionata = 'aggiornamento_non_dirigenti'"
        :class="!readOnly ? 'text-center' : ''"
      >
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_corsi"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta corsi
          aggiornamento non dirigenti
        </button>
      </div>
      <div
        class="col-3"
        @click="esportazione_selezionata = 'formazione_dirigenti'"
        :class="!readOnly ? 'text-center' : ''"
      >
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_corsi"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          corsi formazione dirigenti
        </button>
      </div>
      <div
        class="col-3"
        @click="esportazione_selezionata = 'con_partecipanti'"
        :class="!readOnly ? 'text-center' : ''"
      >
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_corsi"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          corsi con partecipanti
        </button>
      </div>
    </div>
    <div class="row"></div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        corsi_list.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="corsi_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsCorsi"
        @current-change="setCurrentPageCorsi"
      >
        <template v-slot:cell-stagione="{ row: data }">{{
          data.stagione
        }}</template>
        <template v-slot:cell-pubblicazione_sul_sito="{ row: data }">
          <span class="text-success" v-if="data.pubblicabile">SI</span>
          <span class="text-danger" v-else>NO</span>
        </template>
        <template v-slot:cell-tipo_corso="{ row: data }"
          ><div class="tab-long">
            {{ data.corso }} -
            <span>
              {{ data.specializzazione_corso }}
            </span>
            <div
              v-for="(sottotipo, index) in JSON.parse(data.sottotipo_agg)"
              :key="index"
            >
              {{ sottotipo.label }}
            </div>
          </div></template
        >
        <template v-slot:cell-denominazione="{ row: data }"
          ><div class="tab-long">{{ data.denominazione }}</div></template
        >
        <template v-slot:cell-periodo="{ row: data }">
          {{ getFormatDate(data.data_inizio) }} -
          {{ getFormatDate(data.data_fine) }}
        </template>
        <template v-slot:cell-luogo="{ row: data }"
          ><div class="tab-long">{{ data.luogo }}</div></template
        >
        <template v-slot:cell-iscritti="{ row: data }">{{
          data.iscritti
        }}</template>
        <template v-slot:cell-visibile_online="{ row: data }">
          <i
            :class="[
              'bi bi-globe',
              data.visibile_online ? 'text-success' : 'text-black-50',
            ]"
            alt="Visibile online"
            title="Visibile online"
          ></i>
        </template>
        <template v-slot:cell-iscrizione_online="{ row: data }">
          <i
            :class="[
              'bi',
              data.iscrizione_online
                ? 'bi-hand-index-thumb-fill text-success'
                : 'bi-hand-index-thumb text-black-50',
            ]"
            alt="Iscrizione online"
            title="Iscrizione online"
          ></i>
        </template>
        <template v-slot:cell-options="{ row: data }">
          {{ data.options }}
          <div class="btn-group">
            <button
              class="btn btn-sm dropdown p-1"
              data-bs-toggle="modal"
              data-bs-target="#modal_edit_corso"
              @click.prevent="
                viewEditCorso(data.id);
                onlyView = true;
              "
            >
              <i class="bi bi-eye text-dark fs-4"></i>
            </button>
            <button class="btn btn-sm dropdown p-1">
              <router-link :to="`/snm/corsi/iscritti-corso/${data.id}`">
                <i class="bi bi-people text-dark fs-4"></i
              ></router-link>
            </button>
            <button
              class="btn btn-sm dropdown p-1"
              v-if="
                isEnabled('fnCorsiListaAttestatiDaStampare') &&
                data.tipologia_corso == 9
              "
            >
              <!-- && 1 == 2 -->
              <router-link :to="`/snm/corsi/attestati/genera/${data.id}`">
                <i class="bi bi-card-heading text-dark fs-4"></i
              ></router-link>
            </button>

            <button
              v-if="!readOnly"
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_corso"
                @click.prevent="
                  viewEditCorso(data.id);
                  onlyView = false;
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-pen text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Modifica </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                @click.prevent="onlineCorso(data.id)"
                v-if="!data.pubblicabile"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-file-arrow-up text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Pubblica </span>
                    </div>
                  </div>
                </li></a
              ><a href="#" @click.prevent="removeCorso(data.id)">
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Elimina </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <AddCorso @refreshList="refreshList" />
    <EditCorso
      @resetIdCorso="id_corso = null"
      @refreshList="
        $emit('getCorsiList');
        id_corso = null;
      "
      :isLoaded="isLoaded"
      :id_corso="id_corso"
      :onlyView="onlyView"
    />
    <!--  <ViewCorso
      @resetIdCorso="id_corso = null"
      :isLoaded="isLoaded"
      :jsonViewEditCorso="jsonViewEditCorso"
    /> -->
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import AddCorso from "../gestione-corsi/AddCorso.vue";
import EditCorso from "../gestione-corsi/EditCorso.vue";
import { detailCorso, deleteCorso, pubblicaCorso } from "@/requests/snmCorsi";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

import ModalEsportaCorsi from "./ModalEsportaCorsi.vue";

export default {
  name: "TableCorsi",
  components: { Datatable, AddCorso, EditCorso, Loading, ModalEsportaCorsi },
  emits: ["getCorsiList", "resetFilters"],
  props: {},

  setup(props, { emit }) {
    const store = useStore();
    const onlyView = ref(false);

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const setFetchRowsCorsi = (e) => {
      store.commit("setFetchRowsCorsi", e);
      emit("getCorsiList");
    };
    const setCurrentPageCorsi = (page) => {
      store.commit("setCurrentPageCorsi", page);
      emit("getCorsiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnCorsi", columnName);
      store.commit("setSortOrderCorsi", order);
      emit("getCorsiList");
    };

    const refreshList = () => {
      emit("getCorsiList");
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
        sortable: false,
      },
      {
        name: "Pubblicato",
        key: "pubblicazione_sul_sito",
        sortable: false,
      },
      {
        name: "Tipologia",
        key: "tipo_corso",
      },
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Periodo",
        key: "periodo",
        sortable: false,
      },
      {
        name: "Luogo",
        key: "luogo",
        sortable: false,
      },
      {
        name: "Iscritti",
        key: "iscritti",
        sortable: false,
      },
      {
        key: "visibile_online",
        sortable: false,
      },
      {
        key: "iscrizione_online",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const isLoaded = ref(false);
    const jsonViewEditCorso = ref({});
    const id_corso = ref(null);

    const viewDetailCorso = (id) => {
      isLoaded.value = false;
      detailCorso(id).then((res) => {
        isLoaded.value = true;
        jsonViewEditCorso.value = res.results[0];
      });
    };

    const viewEditCorso = (id) => {
      isLoaded.value = false;
      id_corso.value = id;
      /*detailCorso(id).then((res) => {
        isLoaded.value = true;
        jsonViewEditCorso.value = res.results[0];
      });*/
    };

    const isLoading = ref(false);

    const removeCorso = (id) => {
      Swal.fire({
        title: "Sei proprio sicuro di voler eliminare il corso selezionato?",
        text: "Verranno cancellate tutte le informazioni del corso e i relativi partecipanti.",
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          const responseDelete = deleteCorso(id);
          responseDelete.then((value) => {
            if (value.status == 200) {
              Swal.fire({
                html: "Corso eliminato",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              emit("resetFilters");
              emit("getCorsiList");
            } else {
              const strMsg = ref(
                value.data.message
                  ? value.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            isLoading.value = false;
          });
        }
      });
    };

    const onlineCorso = (id) => {
      Swal.fire({
        title: "Sei proprio sicuro di voler pubblicare il corso selezionato?",
        html: "Cliccando sul pulsante <em>Conferma</em> il corso sarà visibile ai tecnici che potranno iscriversi ed eseguire i pagamenti delle quote.<br />Verranno creati inoltre i relativi movimenti per i partecipanti già inseriti.<br /><br /><b class='text-danger'>ATTENZIONE! Un corso pubblicato non può più essere nascosto.</b>",
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          const responsePubblica = pubblicaCorso(id);
          responsePubblica.then((value) => {
            if (value.status == 200) {
              Swal.fire({
                html: "Corso pubblicato",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              emit("getCorsiList");
            } else {
              const strMsg = ref(
                value.data.message
                  ? value.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            isLoading.value = false;
          });
        }
      });
    };

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    const esportazione_selezionata = ref("");

    return {
      esportazione_selezionata,
      getFormatDate,
      corsi_list: computed(() =>
        store.getters.getStateFromName("resultscorsi_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcorsi_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordcorsi_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuscorsi_list")
      ),

      currentPage: computed(() => store.getters.currentPageCorsi),
      rowsToSkip: computed(() => store.getters.rowsToSkipCorsi),
      fetchRows: computed(() => store.getters.fetchRowsCorsi),
      sortColumn: computed(() => store.getters.sortColumnCorsi),
      sortOrder: computed(() => store.getters.sortOrderCorsi),
      setFetchRowsCorsi,
      setCurrentPageCorsi,
      setSortOrderColumn,
      tableHeader,
      isLoaded,
      viewDetailCorso,
      jsonViewEditCorso,
      removeCorso,
      onlineCorso,
      viewEditCorso,
      refreshList,
      id_corso,
      isLoading,
      onlyView,
      isEnabled,
      esportaLista,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>
